@import "@/styles/variables.scss";$STATIC_ASSETS_URL:'https://overseacdn.ydstatic.com';
.container {
  margin-bottom: 240px;
}


.wrapper {
  position: relative;
}

.title {
  position: relative;
  font-size: 60px;
  margin-bottom: 80px;
  font-weight: 600;

  &::after {
    content: '';
    position: absolute;
    top: -40px;
    left: -40px;
    display: block;
    width: 100%;
    height: 100px;
    background-repeat: no-repeat;
    z-index: 0;
  }

  &__zh {
    position: relative;
    z-index: 1;

    &::after {
      background-image: url('#{$STATIC_ASSETS_URL}/overseacdn/homepage/static/images/home/solutions/title-bg-zh.png');
    }
  }

  &__en {
    position: relative;
    z-index: 1;

    &::after {
      top: -30px;
      background-image: url('#{$STATIC_ASSETS_URL}/overseacdn/homepage/static/images/home/solutions/title-bg-zh.png');
    }
  }

  :global {
    .en .format-hlt {
      font-family: Gilroy, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    }

    .zh {
      font-weight: 500;
    }

    .en {
      font-size: 64px;
      line-height: 64px;
    }
  }
}

.list {
  display: flex;

  >li {
    list-style: none;
  }
}

.item {
  flex: 1;
  cursor: pointer;
  transition: color .3s ease-in-out;

  &:hover {
    color: $primary-color;
  }

  &__zh {
    margin-right: 104px;
  }

  &__en {
    margin-right: 50px;
  }

  &:last-child {
    margin-right: 0;
  }

  &__title {
    margin-top: 24px;
    margin-bottom: 16px;
    line-height: 1;
    font-weight: 700;

    :global {
      .zh {
        font-size: 32px;
      }

      .en {
        font-size: 24px;
      }
    }
  }

  &__content {
    font-size: 16px;
    color: $text-primary-color;
    opacity: .8;
    line-height: 1.6;
  }
}