@import "@/styles/variables.scss";$STATIC_ASSETS_URL:'https://overseacdn.ydstatic.com';
.container {
  padding-top: 144px;

  /* header height 100px */
  background-color: white;
}

.wrapper {
  position: relative;
  height: 3000px;
}

.header {
  position: relative;
  text-align: center;
  margin-bottom: 100px;
  background-color: white;

  &::after {
    position: absolute;
    top: -25px;
    left: 50%;
    content: url('#{$STATIC_ASSETS_URL}/overseacdn/homepage/static/images/services/title_bg.png');
    height: 54px;
    width: 468px;
    transform: translate(-50%);
  }
}

.title {
  font-size: 54px;
  font-weight: 600;
  line-height: 1;

  :global {
    .zh {
      font-weight: 500;
    }
  }
}

.nav {
  position: sticky;
  top: 70px;
  background-color: white;
  z-index: 1;
}

.list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  &__zh {
    height: 70px;
  }

  &__en {
    height: 70px;
  }

  &__active {
    border-top: 1px solid #F2F2F2;
    box-shadow: 0 0 30px rgb(0 0 0 / 6%);
  }

  &__item {
    position: relative;
    list-style: none;
    text-align: center;
    cursor: pointer;

    span {
      width: 140px;
      color: #232426;
      text-decoration: none;
    }

    &__active {
      opacity: 1 !important;

      span {
        font-size: 24px !important;
        font-weight: 600;
      }
    }

    &__zh {
      margin-right: 108px;
    }

    &__en {
      margin-right: 120px;
    }

    &:last-child {
      &.list__item__zh {
        margin-right: 0;
      }

      &.list__item__en {
        margin-right: 0;
      }
    }

    &__spline {
      position: absolute;
      left: 0;
      bottom: -16px;
      width: 100%;
      height: 7px;

      &::after {
        position: absolute;
        content: '';
        top: 100%;
        left: 50%;
        display: block;
        width: 40px;
        height: 100%;
        background-color: $primary-color;
        transform: translate(-50%, -50%) skewX(-20deg);
        border-bottom-right-radius: 4px;
        border-top-left-radius: 4px;
      }
    }
  }

  li {
    opacity: 0.8;

    span {
      font-size: 18px;
    }
  }
}

.highlight {
  width: 100%;
  max-width: none;
  height: 750px;
  background-size: 1920px 750px;
  background-repeat: no-repeat;
  background-position: center;
  scroll-margin-top: 132px;

  &__wrapper {
    margin: 0 auto;
    max-width: 1200px;
    height: 750px;
    display: flex;
    align-items: center;
    position: relative;

    .title__bg1::after,
    .title__bg2::after,
    .title__bg3::after,
    .title__bg4::after {
      position: absolute;
      top: -20px;
      left: 0;
    }

    .title__bg1::after {
      content: url('#{$STATIC_ASSETS_URL}/overseacdn/homepage/static/images/services/title_bg1.png');
    }

    .title__bg2::after {
      content: url('#{$STATIC_ASSETS_URL}/overseacdn/homepage/static/images/services/title_bg2.png');
    }

    .title__bg3::after {
      content: url('#{$STATIC_ASSETS_URL}/overseacdn/homepage/static/images/services/title_bg3.png');
    }

    .title__bg4::after {
      content: url('#{$STATIC_ASSETS_URL}/overseacdn/homepage/static/images/services/title_bg4.png');
    }
  }

  &__title {
    position: relative;
    font-size: 40px;
    font-weight: 600;
    line-height: 1;

    :global {
      .zh {
        font-weight: 500;
      }
    }
  }

  &__description {
    margin-top: 24px;
    margin-bottom: 36px;
    font-size: 18px;
    opacity: 0.7;

    :global {
      .zh {
        line-height: 1.6;
      }

      .en {
        line-height: 1.5;
      }
    }

    &__zh__0 {
      width: 471px;
    }

    &__zh__1 {
      width: 480px;
    }

    &__zh__2 {
      width: 471px;
    }

    &__zh__3 {
      width: 496px;
      font-size: 16px;
    }

    &__en__0 {
      width: 538px;
    }

    &__en__1 {
      width: 517px;
    }

    &__en__2 {
      width: 519px;
    }

    &__en__3 {
      width: 517px;
    }
  }

  &__summary {
    :global {
      .zh {
        line-height: 2.4;
      }

      .en {
        line-height: 2;
      }
    }

    li {
      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;
      margin-left: 1em;
      color: #232426;
      margin-bottom: 16px;
    }

    &__en__0 {
      width: 568px;
    }

    &__en__1 {
      width: 530px;
    }

    &__en__2 {
      width: 533px;
    }

    &__en__3 {
      width: 528px;
    }
  }

  &__img {
    flex: 1;
  }
}

.highlight__bg0 {
  width: 100%;
}