@import "@/styles/variables.scss";$STATIC_ASSETS_URL:'https://overseacdn.ydstatic.com';
.container {
  width: 100%;
  background-color: $primary-color;
  height: 580px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 0;
  background-position-y: center;
  margin-bottom: 270px;

  .bg {
    position: absolute;
    height: 580px;
    width: calc(50% - 400px);
    background-color: #F7F6F5;
  }

  .content {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    position: relative;
    min-width: 1200px;
    background-repeat: no-repeat;
    background-position: 65%;
  }
}

.img {
  &__container {
    flex-basis: 580px;
    max-width: 48%;
    position: relative;
  }

  &__wrapper {
    display: block;
    height: auto;

    &::after {
      content: attr(data-title);
      font-family: 'HarmonyOS Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      position: absolute;
      top: 0;
      left: 430px;
      width: 100%;
      height: 84px;
      display: block;
      color: transparent;
      font-size: 100px;
      opacity: .4;
      font-weight: 700;
      -webkit-text-stroke: #CC1B00 2px;
      text-transform: uppercase;
    }

    img {
      display: block;
      height: 580px;
    }
  }
}

.wrapper {
  flex-basis: 600px;
  min-width: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  line-height: 1;
  margin-left: 50px;
  z-index: 1;
}

.title {
  position: relative;
  font-size: 60px;
  margin-bottom: 80px;
  font-weight: 600;
  z-index: 0;

  :global {
    .zh {
      font-weight: 500;
      line-height: 80px;
    }

    .en {
      font-size: 64px;
      line-height: 64px;
    }
  }
}

.highlight {
  width: 600px;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    color: white;
  }

  &__item {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;

    &:nth-child(-n+3) {
      margin-bottom: 60px;
    }

    &__wrapper {
      display: flex;
      align-items: flex-end;
      margin-bottom: 8px;
    }

    &__value {
      font-size: 48px;
      line-height: 1;
      font-weight: 700;

      // font-style: italic;
      transform: skew(-5deg);
      font-family: 'HarmonyOS Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      margin-right: 4px;
    }

    &__unit {
      margin-bottom: 5px;

      &::after {
        content: '+';
      }
    }
  }
}