@import "@/styles/variables.scss";$STATIC_ASSETS_URL:'https://overseacdn.ydstatic.com';
$tabs-border-radius: 8px;

.container {
  // max-width: 1200px;
  margin: 0 auto;
  padding-top: 264px;
}

.wrapper {
  position: relative;
  line-height: 1;
  text-align: center;
}

.title__en,
.title__zh {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 28px;

  :global {
    .en {
      font-size: 64px;
      line-height: 68px;
    }
  }
}

.title__zh {
  &::after {
    content: attr(data-title);
    font-family: 'HarmonyOS Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: absolute;
    top: -48px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    font-size: 100px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    -webkit-text-stroke: $text-primary-color 2px;
    opacity: .06;
    z-index: 0;
  }
}

.title__en {
  &::after {
    content: url('#{$STATIC_ASSETS_URL}/overseacdn/homepage/static/images/home/partners/title-bg.png');
    position: absolute;
    top: -30px;
    left: 410px;
    width: 210px;
    z-index: 0;
  }
}

.subtitle {
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 60px;
  opacity: .8;
  color: $text-primary-color;
}

.tabs {
  position: relative;

  &__wrapper {
    position: relative;
    display: flex;
    width: 624px;
    margin: 0 auto;
    height: 56px;
    color: $text-primary-color;
    margin-bottom: -20px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($color: $primary-color, $alpha: 10%);
      border-radius: $tabs-border-radius;
      transform: skewX(-20deg);
      z-index: 0;
    }
  }

  &__item {
    flex: 0 0 208px;
    position: relative;
    list-style: none;
    cursor: pointer;
    z-index: 1;
    transition: color .3s ease-in-out;

    &:hover {
      color: $primary-color;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      white-space: nowrap;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    &__active {
      color: white;

      &:hover {
        color: white;
      }
    }
  }

  &__spline {
    position: absolute;
    top: 0;
    left: 0;
    width: 208px;
    height: 100%;
    z-index: 0;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $primary-color;
      border-radius: $tabs-border-radius;
      transform: skewX(-20deg);
    }
  }

  &__img {
    // height: 336px;
  }
}