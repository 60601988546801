@import "@/styles/variables.scss";$STATIC_ASSETS_URL:'https://overseacdn.ydstatic.com';
.container {
  max-width: 889px;
  position: relative;
  border-radius: 20px;
  margin: 0 auto;
}

.video {
  &__container {
    margin: 0 auto;
    border-radius: 20px;
    height: 500px;
  }
}