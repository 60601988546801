@import "@/styles/variables.scss";$STATIC_ASSETS_URL:'https://overseacdn.ydstatic.com';
.container {
  position: relative;
  height: 450px;
  padding-top: 70px;

  /* header height 100px */
  margin-bottom: 312px;

  /* 240 + 115 */
  background-color: $primary-color;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('data:image/svg+xml,<svg width="120" height="125" viewBox="0 0 120 125.36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.3439 1H58.6361L47.1469 37.4354C45.7031 42.0139 41.4568 45.1273 36.6561 45.1273H1.36387L12.8531 8.69192C14.2969 4.11339 18.5432 1 23.3439 1Z" stroke="white" stroke-width="2"/><path d="M71.8994 8.62751C73.4744 3.63276 78.1067 0.236328 83.3439 0.236328H120L108.101 37.9724C106.526 42.9672 101.893 46.3636 96.6561 46.3636H60L71.8994 8.62751Z" fill="white"/><path d="M83.3439 64.2363H118.636L107.147 100.672C105.703 105.25 101.457 108.364 96.6561 108.364H61.3639L72.8531 71.9282C74.2969 67.3497 78.5432 64.2363 83.3439 64.2363Z" stroke="white" stroke-width="2"/><path d="M11.8994 71.6275C13.4744 66.6328 18.1067 63.2363 23.3439 63.2363H60L48.1006 100.972C46.5256 105.967 41.8933 109.364 36.6561 109.364H0L11.8994 71.6275Z" fill="white"/></svg>');
    opacity: 0.06;
    z-index: 0;
  }

  * {
    position: relative;
    z-index: 1;
  }
}