@import "@/styles/variables.scss";$STATIC_ASSETS_URL:'https://overseacdn.ydstatic.com';
.container {
  background-color: #f7f6f5;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 690px;
  padding: 120px 0;

  &::before,
  &::after {
    position: absolute;
    left: -80px;
  }

  &::before {
    content: '';
    top: 0;
    display: block;
    background-color: #f7f6f5;
    height: 38px;
    width: 700px;
    z-index: 1;
  }

  &::after {
    content: attr(data-title);
    width: 100%;
    font-family: 'HarmonyOS Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    top: -72px;
    left: -40px;
    color: white;
    font-size: 100px;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
    -webkit-text-stroke: $text-primary-color 2px;
    opacity: .06;
    z-index: 0;
  }
}

.title {
  line-height: 1;

  :global {
    .zh,
    .en {
      display: inline-block;
      width: 100%;
    }

    .zh {
      font-size: 60px;
      font-weight: 500;
      margin-bottom: 100px;

      .format-newline {
        margin-bottom: 12px;
      }
    }

    .en {
      font-size: 64px;
      line-height: 64px;
      font-weight: 700;
      margin-bottom: 64px;

      .format-newline {
        margin-bottom: 4px;
      }
    }
  }
}


.highlight {
  flex: 1;

  &__title {
    font-size: 30px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 16px;
  }

  &__content {
    height: 154px;
    line-height: 1.6;
    opacity: .8;

    :global {
      .zh,
      .en {
        display: inline-block;
      }

      .zh {
        width: 540px;
      }

      .en {
        width: 569px;
      }
    }
  }

  &__img {
    position: absolute;
    right: -85px;
    top: -70px;

    &::before,
    &::after {
      position: absolute;
      color: white;
      font-family: 'HarmonyOS Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      ;
    }

    &::before {
      top: 18px;
      left: 150px;
      content: attr(data-index);
      font-size: 64px;
      line-height: 1;
      font-weight: 700;
    }

    &::after {
      top: 52px;
      left: 232px;
      content: '/ 05';
      font-size: 24px;
      line-height: 1;
    }
  }
}

.actions {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.pre {
  margin-right: 20px;
}

.pre,
.next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: 1px none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: $primary-color;
  }

}